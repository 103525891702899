import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    selectedVoltageType: { label: "Line to Line", value: "L-L" },
};

const voltageTypeSlice = createSlice({
    name: "voltageType",
    initialState: INITIAL_STATE,
    reducers: {
        setVoltageType(state, action) {
            return {
                ...state,
                selectedVoltageType: action.payload,
            };
        },
    },
});

export const voltageTypeActions = voltageTypeSlice.actions;

export default voltageTypeSlice;
